<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학자재 별 유해성/위해성 평가결과"
      tableId="riskHazrd01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'mdmChemMaterialId' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
          { index: 3, colName: 'mdmChemMaterialId' },
          { index: 6, colName: 'mdmChemMaterialId' },
          { index: 7, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'mdmChemMaterialId',
            field: 'mdmChemMaterialId',
            label: '화학자재 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '제조업체',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '구성물질',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '유해성 평가결과',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_APPRAISE_MALEFICENCE',
            keyText: 'mdmChemMaterialId',
            sortable: false,
          },
          {
            name: 'col8',
            field: 'col8',
            label: '위해성 평가결과',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_APPRAISE_RISK',
            keyText: 'mdmChemMaterialId',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.mam.chem.msds.url;
      this.getList();
    },
    getList() {

      if (!this.editable) {
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'mdmChemMaterialId',
            field: 'mdmChemMaterialId',
            label: '화학자재 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '제조업체',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'chemNmKr',
            field: 'chemNmKr',
            label: '구성물질',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '유해성 평가결과',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_APPRAISE_MALEFICENCE',
            keyText: 'mdmChemMaterialId',
            sortable: false,
            disableTarget: 'useFlag',
            disableCon: 'X',
          },
          {
            name: 'col8',
            field: 'col8',
            label: '위해성 평가결과',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_APPRAISE_RISK',
            keyText: 'mdmChemMaterialId',
            sortable: false,
            disableTarget: 'useFlag',
            disableCon: 'X',
          },
        ];
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.$refs['table'].uploadResearchSetting(row, ['col7', 'col8']);
      this.popupOptions.title = '화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
        plantCd: row ? row.plantCd : '',
        tabName: 'chemEtc',
        subtabName: 'chemAppraiseFile',
      };
      this.popupOptions.target = () => import(`${'./chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.$refs['table'].uploadResearch();
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
